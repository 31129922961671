<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5332 5.02542L12.9666 3.77542L11.7166 3.20876L12.9666 2.64209L13.5332 1.39209L14.0999 2.64209L15.3499 3.20876L14.0999 3.77542L13.5332 5.02542ZM5.49989 5.02542L4.93322 3.77542L3.68322 3.20876L4.93322 2.64209L5.49989 1.39209L6.06656 2.64209L7.31656 3.20876L6.06656 3.77542L5.49989 5.02542ZM13.5332 13.0588L12.9666 11.8088L11.7166 11.2421L12.9666 10.6754L13.5332 9.42542L14.0999 10.6754L15.3499 11.2421L14.0999 11.8088L13.5332 13.0588ZM3.11655 15.2088L1.53322 13.6254C1.411 13.5032 1.34433 13.3615 1.33322 13.2004C1.32211 13.0393 1.38878 12.8865 1.53322 12.7421L9.03322 5.24209C9.16655 5.10876 9.32767 5.04209 9.51655 5.04209C9.70544 5.04209 9.86655 5.10876 9.99989 5.24209L11.4999 6.74209C11.6332 6.87542 11.6999 7.03653 11.6999 7.22542C11.6999 7.41431 11.6332 7.57542 11.4999 7.70876L3.99989 15.2088C3.86655 15.3421 3.71933 15.4088 3.55822 15.4088C3.39711 15.4088 3.24989 15.3421 3.11655 15.2088ZM9.49989 8.27542L10.5332 7.24209L9.49989 6.20876L8.46655 7.24209L9.49989 8.27542Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcMagicWand',
  props: {
    size: {
      type: [String, Number],
      default: 16,
    },
  },
}
</script>

<style scoped lang="scss"></style>
